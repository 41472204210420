<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="Chương trình đào tạo/Bồi dưỡng"
                      label-for="trainingSystemSelect"
                    >
                      <v-select
                        v-model="trainingSystemSelect"
                        label="name"
                        :options="allTrainingSystems"
                        :reduce="(option) => option.id"
                        placeholder="Chương trình đào tạo/Bồi dưỡng"
                        @input="onChangeTrainingSystem"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      title="Khóa - Học kỳ"
                      label-for="coursesSemestersSelected"
                    >
                      <v-select
                        v-model="coursesSemestersSelected"
                        label="name"
                        :options="coursesSemesters"
                        :reduce="(option) => option.id"
                        placeholder="Khóa - Học kỳ"
                        @input="onChangeCoursesSemester"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="Học phần"
                      label-for="subjectSelect"
                    >
                      <v-select
                        v-model="courseSemesterSubjectSelect"
                        label="name"
                        :options="courseSemesterSubject"
                        :reduce="(option) => option.id"
                        placeholder="Học phần"
                        @input="onChangeCourseSemesterSubject"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <div class="text-left">
                      <b-button
                        v-show="checkShowButton"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        class="mr-1"
                        @click="onSelectExams"
                      >
                        <span class="text-nowrap text-right">
                          Danh sách đề thi
                        </span>
                      </b-button>
                      <b-button
                        v-show="qbExams.length > 0"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="mr-1"
                        @click="onApproveExam"
                      >
                        <span class="text-nowrap text-right">Thêm đề thi vào học kỳ</span>
                      </b-button>
                      <b-button
                        v-show="qbExams.length > 0"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="onSelectExamsSelected"
                      >
                        <span class="text-nowrap text-right">Danh sách đề thi trong học kỳ</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= dataTotalRows)? dataTotalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ dataTotalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSource"
                  :pagination-options="paginationOptions"
                  :total-rows="dataTotalRows"
                  :select-options="{ enabled: !checkButtonShowDataSelected, selectOnCheckboxOnly: true, selectionText: 'Đề thi được chọn', clearSelectionText: 'Bỏ chọn' }"
                  line-numbers
                  @on-selected-rows-change="selectionChanged"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-if="deletable && checkButtonShowDataSelected"
                          variant="danger"
                          :disabled="props.row.proclaim !== 1"
                          class="btn-icon"
                          style="margin-left: 6px"
                          size="sm"
                          @click="onDeleteMainData(props.row)"
                        >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                        <b-button
                          v-if="proclaim && checkButtonShowDataSelected"
                          variant="info "
                          class="btn-icon"
                          size="sm"
                          @click="onProclaimMainData(props.row)"
                        >
                        <feather-icon icon="ShareIcon" />
                      </b-button>
                      <b-dropdown v-if="checkShowButton && exportPdfExam" right variant="success">
                        <b-dropdown-item @click="exportPdfStudent(props.row)">
                          <span class="text-nowrap text-right">
                            <feather-icon icon="FileIcon" /> Xuất đề .PDF cho học viên
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportWordStudent(props.row)">
                          <span class="text-nowrap text-right">
                            <feather-icon icon="FileIcon" /> Xuất đề .DOC cho học viên
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportPdfTeacher(props.row)">
                          <span class="text-nowrap text-right">
                            <feather-icon icon="FileIcon" /> Xuất đề .PDF cho giảng viên
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportWordTeacher(props.row)">
                          <span class="text-nowrap text-right">
                            <feather-icon icon="FileIcon" /> Xuất đề .DOC cho giảng viên
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                      </b-button-group>
                    </span>
                    <span v-else-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= dataTotalRows)? dataTotalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ dataTotalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="dataTotalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup,
  BCard,
  BCol,
  BContainer, BDropdown, BDropdownItem,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import { QB_EXAM_STATUSES } from '@/const/status'

export default {
  name: 'ExaminationRoom',
  directives: {
    Ripple,
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      trainingSystemSelect: undefined,
      coursesSemestersSelected: undefined,
      courseSemesterSubjectSelect: undefined,
      checkButtonShowDataSelected: false,
      showButtonExport: false,
      showDropdown: false,
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        subjectId: null,
        courseSemesterId: null,
        code: null,
        name: null,
        status: null,
      },
      paginationOptions: {
        enabled: true,
      },
      selectedMainData: [],
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      dataSource: [],
      dataTotalRows: 0,
      checkShowButton: false,
    }
  },
  computed: {
    ...mapGetters({
      qbExams: 'qbExams/dataSourcesByStatus',
      qbExamsSelected: 'qbExams/dataSourcesSelected',
      totalRows: 'qbExams/totalRowsByStatus',
      totalRowsSelected: 'qbExams/totalRowsSelected',
      allTrainingSystems: 'qbExams/allTrainingSystems',
      coursesSemesters: 'qbExams/coursesSemesters',
      courseSemesterSubject: 'qbExams/listCourseSemesterSubject',
    }),
    columns() {
      const baseColumns = [
        {
          label: 'Tên đề thi',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học phần',
          field: 'subjectName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
        },
      ]
      if (!this.checkButtonShowDataSelected) {
        baseColumns.push(
          {
            label: 'Học kỳ đã thi',
            field: 'endDate',
            sortable: false,
            thClass: 'text-center',
          },
        )
      }
      if (this.checkButtonShowDataSelected) {
        baseColumns.push(
          {
            label: '',
            field: 'action',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        )
      }
      return baseColumns
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.QB_EXAM_COURSE_SEMESTER)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.QB_EXAM_COURSE_SEMESTER)
    },
    proclaim() {
      return isSystemAdmin() ||  hasPermissionForResource(PermissionCode.CREATE, ResourceCode.QB_EXAM_COURSE_SEMESTER)
    },
    exportPdfExam() {
      return isSystemAdmin() ||  hasPermissionForResource(PermissionCode.FETCH, ResourceCode.EXPORT_QB_EXAM_COURSE_SEMESTER)
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'qbExams/getTrainingSystemsByOrganizationId',
      getCourseSemesters: 'qbExams/getCourseSemesters',
      getCourseSemesterSubjectSelect: 'qbExams/getCourseSemesterSubject',
      getQbExams: 'qbExams/getDataSourcesByStatus',
      getQbExamsSelected: 'qbExams/getDataSourcesSelected',
      createExamCourseSemester: 'qbExams/createExamCourseSemester',
      deleteExamCourseSemester: 'qbExams/deleteExamCourseSemester',
      exportExam: 'qbExams/exportPdf',
      exportWordExam: 'qbExams/exportWordExam',
      exportExamTeacher: 'qbExams/exportExamTeacher',
      exportWordExamTeacher: 'qbExams/exportWordExamTeacher',
      getProclaimExam: 'qbExams/getProclaimExam',
    }),
    async onChangeTrainingSystem() {
      if (this.trainingSystemSelect == null) {
        this.reset()
        this.$store.commit('qbExams/SET_COURSES_SEMESTERS', {
          data: [],
        })
        this.$store.commit('qbExams/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.coursesSemestersSelected = null
        this.courseSemesterSubjectSelect = null
      } else {
        await this.getCourseSemesters({
          organizationId: getUser().orgId,
          training_system_id: this.trainingSystemSelect,
        })
      }
    },
    async onChangeCoursesSemester() {
      if (this.coursesSemestersSelected == null) {
        this.reset()
        this.$store.commit('qbExams/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.courseSemesterSubjectSelect = null
      } else {
        await this.getCourseSemesterSubjectSelect({ organizationId: getUser().orgId, courses_semester_id: this.coursesSemestersSelected, hasExam: 1 })
      }
    },
    async onChangeCourseSemesterSubject() {
      if (this.courseSemesterSubjectSelect == null) {
        this.reset()
      } else {
        this.checkShowButton = true
      }
    },
    reset() {
      this.$store.commit('qbExams/SET_DATA_SOURCES_BY_STATUS', {
        total: 0,
        records: [],
      })
      this.checkShowButton = false
    },
    async onSelectExams() {
      this.checkButtonShowDataSelected = false
      await this.getExamsFromStore()
    },
    async onSelectExamsSelected() {
      this.checkButtonShowDataSelected = true
      this.showButtonExport = true
      await this.getExamsFromStore()
    },
    async exportPdfStudent(dataExam) {
      this.isLoading = true
      try {
        const data = await this.exportExam({
          qbExamId: dataExam.id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportWordStudent(dataExam) {
      this.isLoading = true
      try {
        const data = await this.exportWordExam({
          qbExamId: dataExam.id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportWordTeacher(dataExam) {
      this.isLoading = true
      try {
        const data = await this.exportWordExamTeacher({
          qbExamId: dataExam.id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfTeacher(dataExam) {
      this.isLoading = true
      try {
        const data = await this.exportExamTeacher({
          qbExamId: dataExam.id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getExamsFromStore() {
      this.isLoading = true
      try {
        this.filter.subjectId = this.courseSemesterSubjectSelect
        this.filter.courseSemesterId = this.coursesSemestersSelected
        await Promise.all([
          this.getQbExams(this.filter),
          this.getQbExamsSelected(this.filter),
        ])
        this.dataSource = this.checkButtonShowDataSelected ? this.qbExamsSelected : this.qbExams
        this.dataTotalRows = this.checkButtonShowDataSelected ? this.totalRowsSelected : this.totalRows
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onApproveExam() {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn đề thi!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.isLoading = true
      try {
        const response = await this.createExamCourseSemester({
          courseSemesterId: this.coursesSemestersSelected,
          qbExamCourseSemesterReqDtos: this.selectedMainData,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await this.getExamsFromStore()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onProclaimMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn công bố đề thi <span class="text-danger">${mainData.name}</span> trong học kỳ?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.getProclaimExam({ qbExamCourseSemesterId: mainData.qbExamCourseSemesterId })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getExamsFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa đề thi <span class="text-danger">${mainData.name}</span> ra khỏi học kỳ?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteExamCourseSemester(mainData.qbExamCourseSemesterId)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getExamsFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => ({ qbExamId: e.id })) : []
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getExamsFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getExamsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getExamsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'subject')) this.updateParams({ subject: columnFilters.subject })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getExamsFromStore()
    },
    getStatusName(id) {
      return QB_EXAM_STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getExamsFromStore()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
